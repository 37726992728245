@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "animate.css/animate";

$bootstrap-icons-font-dir: "~bootstrap-icons/font/fonts";
@import "bootstrap-icons/font/bootstrap-icons";

@import "boxicons/css/boxicons.css";
@import "glightbox/dist/css/glightbox.css";
@import "swiper/css/bundle";
@import "style.css";

.member-info {
  .about {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.project-tile {
  cursor: pointer;
}

.form-control.form__row--error {
  border-color: #c53434;
}

.form__errors {
  color: #c53434;
}

.nav-link.lang-swich {
  display: none;
}

@media (max-width: 991px) {
  .nav-link.lang-swich {
    display: block;
  }
}
